"use strict";
exports.__esModule = true;
require('./panel.css');
exports.default = {
  "modifiers": {
    "bordered": "pf-m-bordered",
    "raised": "pf-m-raised",
    "scrollable": "pf-m-scrollable"
  },
  "panel": "pf-c-panel",
  "panelFooter": "pf-c-panel__footer",
  "panelHeader": "pf-c-panel__header",
  "panelMain": "pf-c-panel__main",
  "panelMainBody": "pf-c-panel__main-body"
};
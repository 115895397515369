"use strict";
exports.__esModule = true;
require('./divider.css');
exports.default = {
  "divider": "pf-c-divider",
  "modifiers": {
    "hidden": "pf-m-hidden",
    "hiddenOnSm": "pf-m-hidden-on-sm",
    "visibleOnSm": "pf-m-visible-on-sm",
    "hiddenOnMd": "pf-m-hidden-on-md",
    "visibleOnMd": "pf-m-visible-on-md",
    "hiddenOnLg": "pf-m-hidden-on-lg",
    "visibleOnLg": "pf-m-visible-on-lg",
    "hiddenOnXl": "pf-m-hidden-on-xl",
    "visibleOnXl": "pf-m-visible-on-xl",
    "hiddenOn_2xl": "pf-m-hidden-on-2xl",
    "visibleOn_2xl": "pf-m-visible-on-2xl",
    "vertical": "pf-m-vertical",
    "insetNone": "pf-m-inset-none",
    "insetXs": "pf-m-inset-xs",
    "insetSm": "pf-m-inset-sm",
    "insetMd": "pf-m-inset-md",
    "insetLg": "pf-m-inset-lg",
    "insetXl": "pf-m-inset-xl",
    "inset_2xl": "pf-m-inset-2xl",
    "inset_3xl": "pf-m-inset-3xl",
    "horizontalOnSm": "pf-m-horizontal-on-sm",
    "verticalOnSm": "pf-m-vertical-on-sm",
    "insetNoneOnSm": "pf-m-inset-none-on-sm",
    "insetXsOnSm": "pf-m-inset-xs-on-sm",
    "insetSmOnSm": "pf-m-inset-sm-on-sm",
    "insetMdOnSm": "pf-m-inset-md-on-sm",
    "insetLgOnSm": "pf-m-inset-lg-on-sm",
    "insetXlOnSm": "pf-m-inset-xl-on-sm",
    "inset_2xlOnSm": "pf-m-inset-2xl-on-sm",
    "inset_3xlOnSm": "pf-m-inset-3xl-on-sm",
    "horizontalOnMd": "pf-m-horizontal-on-md",
    "verticalOnMd": "pf-m-vertical-on-md",
    "insetNoneOnMd": "pf-m-inset-none-on-md",
    "insetXsOnMd": "pf-m-inset-xs-on-md",
    "insetSmOnMd": "pf-m-inset-sm-on-md",
    "insetMdOnMd": "pf-m-inset-md-on-md",
    "insetLgOnMd": "pf-m-inset-lg-on-md",
    "insetXlOnMd": "pf-m-inset-xl-on-md",
    "inset_2xlOnMd": "pf-m-inset-2xl-on-md",
    "inset_3xlOnMd": "pf-m-inset-3xl-on-md",
    "horizontalOnLg": "pf-m-horizontal-on-lg",
    "verticalOnLg": "pf-m-vertical-on-lg",
    "insetNoneOnLg": "pf-m-inset-none-on-lg",
    "insetXsOnLg": "pf-m-inset-xs-on-lg",
    "insetSmOnLg": "pf-m-inset-sm-on-lg",
    "insetMdOnLg": "pf-m-inset-md-on-lg",
    "insetLgOnLg": "pf-m-inset-lg-on-lg",
    "insetXlOnLg": "pf-m-inset-xl-on-lg",
    "inset_2xlOnLg": "pf-m-inset-2xl-on-lg",
    "inset_3xlOnLg": "pf-m-inset-3xl-on-lg",
    "horizontalOnXl": "pf-m-horizontal-on-xl",
    "verticalOnXl": "pf-m-vertical-on-xl",
    "insetNoneOnXl": "pf-m-inset-none-on-xl",
    "insetXsOnXl": "pf-m-inset-xs-on-xl",
    "insetSmOnXl": "pf-m-inset-sm-on-xl",
    "insetMdOnXl": "pf-m-inset-md-on-xl",
    "insetLgOnXl": "pf-m-inset-lg-on-xl",
    "insetXlOnXl": "pf-m-inset-xl-on-xl",
    "inset_2xlOnXl": "pf-m-inset-2xl-on-xl",
    "inset_3xlOnXl": "pf-m-inset-3xl-on-xl",
    "horizontalOn_2xl": "pf-m-horizontal-on-2xl",
    "verticalOn_2xl": "pf-m-vertical-on-2xl",
    "insetNoneOn_2xl": "pf-m-inset-none-on-2xl",
    "insetXsOn_2xl": "pf-m-inset-xs-on-2xl",
    "insetSmOn_2xl": "pf-m-inset-sm-on-2xl",
    "insetMdOn_2xl": "pf-m-inset-md-on-2xl",
    "insetLgOn_2xl": "pf-m-inset-lg-on-2xl",
    "insetXlOn_2xl": "pf-m-inset-xl-on-2xl",
    "inset_2xlOn_2xl": "pf-m-inset-2xl-on-2xl",
    "inset_3xlOn_2xl": "pf-m-inset-3xl-on-2xl"
  }
};
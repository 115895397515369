import { createIcon } from '../createIcon';

export const UnlockIconConfig = {
  name: 'UnlockIcon',
  height: 512,
  width: 448,
  svgPath: 'M400 256H152V152.9c0-39.6 31.7-72.5 71.3-72.9 40-.4 72.7 32.1 72.7 72v16c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24v-16C376 68 307.5-.3 223.5 0 139.5.3 72 69.5 72 153.5V256H48c-26.5 0-48 21.5-48 48v160c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z',
  yOffset: 0,
  xOffset: 0,
};

export const UnlockIcon = createIcon(UnlockIconConfig);

export default UnlockIcon;
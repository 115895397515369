"use strict";
exports.__esModule = true;
require('./label-group.css');
exports.default = {
  "button": "pf-c-button",
  "labelGroup": "pf-c-label-group",
  "labelGroupClose": "pf-c-label-group__close",
  "labelGroupLabel": "pf-c-label-group__label",
  "labelGroupList": "pf-c-label-group__list",
  "labelGroupListItem": "pf-c-label-group__list-item",
  "labelGroupMain": "pf-c-label-group__main",
  "labelGroupTextarea": "pf-c-label-group__textarea",
  "modifiers": {
    "category": "pf-m-category",
    "vertical": "pf-m-vertical",
    "editable": "pf-m-editable",
    "textarea": "pf-m-textarea"
  }
};
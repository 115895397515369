"use strict";
exports.__esModule = true;
require('./alert.css');
exports.default = {
  "alert": "pf-c-alert",
  "alertAction": "pf-c-alert__action",
  "alertActionGroup": "pf-c-alert__action-group",
  "alertDescription": "pf-c-alert__description",
  "alertIcon": "pf-c-alert__icon",
  "alertTitle": "pf-c-alert__title",
  "alertToggle": "pf-c-alert__toggle",
  "alertToggleIcon": "pf-c-alert__toggle-icon",
  "button": "pf-c-button",
  "modifiers": {
    "success": "pf-m-success",
    "danger": "pf-m-danger",
    "warning": "pf-m-warning",
    "info": "pf-m-info",
    "inline": "pf-m-inline",
    "plain": "pf-m-plain",
    "expandable": "pf-m-expandable",
    "expanded": "pf-m-expanded",
    "truncate": "pf-m-truncate",
    "overpassFont": "pf-m-overpass-font"
  },
  "themeDark": "pf-theme-dark"
};
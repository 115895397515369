"use strict";
exports.__esModule = true;
require('./input-group.css');
exports.default = {
  "formControl": "pf-c-form-control",
  "inputGroup": "pf-c-input-group",
  "inputGroupText": "pf-c-input-group__text",
  "modifiers": {
    "plain": "pf-m-plain",
    "disabled": "pf-m-disabled"
  },
  "themeDark": "pf-theme-dark"
};
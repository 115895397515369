"use strict";
exports.__esModule = true;
require('./expandable-section.css');
exports.default = {
  "expandableSection": "pf-c-expandable-section",
  "expandableSectionContent": "pf-c-expandable-section__content",
  "expandableSectionToggle": "pf-c-expandable-section__toggle",
  "expandableSectionToggleIcon": "pf-c-expandable-section__toggle-icon",
  "expandableSectionToggleText": "pf-c-expandable-section__toggle-text",
  "modifiers": {
    "expanded": "pf-m-expanded",
    "detached": "pf-m-detached",
    "truncate": "pf-m-truncate",
    "limitWidth": "pf-m-limit-width",
    "displayLg": "pf-m-display-lg",
    "indented": "pf-m-indented",
    "active": "pf-m-active",
    "expandTop": "pf-m-expand-top",
    "overpassFont": "pf-m-overpass-font"
  }
};
"use strict";
exports.__esModule = true;
require('./table.css');
exports.default = {
  "button": "pf-c-button",
  "modifiers": {
    "hidden": "pf-m-hidden",
    "hiddenOnSm": "pf-m-hidden-on-sm",
    "visibleOnSm": "pf-m-visible-on-sm",
    "hiddenOnMd": "pf-m-hidden-on-md",
    "visibleOnMd": "pf-m-visible-on-md",
    "hiddenOnLg": "pf-m-hidden-on-lg",
    "visibleOnLg": "pf-m-visible-on-lg",
    "hiddenOnXl": "pf-m-hidden-on-xl",
    "visibleOnXl": "pf-m-visible-on-xl",
    "hiddenOn_2xl": "pf-m-hidden-on-2xl",
    "visibleOn_2xl": "pf-m-visible-on-2xl",
    "fixed": "pf-m-fixed",
    "stickyHeader": "pf-m-sticky-header",
    "nestedColumnHeader": "pf-m-nested-column-header",
    "borderRow": "pf-m-border-row",
    "striped": "pf-m-striped",
    "expandable": "pf-m-expandable",
    "stripedEven": "pf-m-striped-even",
    "ghostRow": "pf-m-ghost-row",
    "center": "pf-m-center",
    "help": "pf-m-help",
    "favorite": "pf-m-favorite",
    "borderRight": "pf-m-border-right",
    "borderLeft": "pf-m-border-left",
    "truncate": "pf-m-truncate",
    "wrap": "pf-m-wrap",
    "nowrap": "pf-m-nowrap",
    "fitContent": "pf-m-fit-content",
    "breakWord": "pf-m-break-word",
    "noBorderRows": "pf-m-no-border-rows",
    "expanded": "pf-m-expanded",
    "hoverable": "pf-m-hoverable",
    "selected": "pf-m-selected",
    "firstCellOffsetReset": "pf-m-first-cell-offset-reset",
    "dragOver": "pf-m-drag-over",
    "favorited": "pf-m-favorited",
    "noPadding": "pf-m-no-padding",
    "compact": "pf-m-compact",
    "width_10": "pf-m-width-10",
    "width_15": "pf-m-width-15",
    "width_20": "pf-m-width-20",
    "width_25": "pf-m-width-25",
    "width_30": "pf-m-width-30",
    "width_35": "pf-m-width-35",
    "width_40": "pf-m-width-40",
    "width_45": "pf-m-width-45",
    "width_50": "pf-m-width-50",
    "width_60": "pf-m-width-60",
    "width_70": "pf-m-width-70",
    "width_80": "pf-m-width-80",
    "width_90": "pf-m-width-90",
    "width_100": "pf-m-width-100"
  },
  "table": "pf-c-table",
  "tableAction": "pf-c-table__action",
  "tableButton": "pf-c-table__button",
  "tableButtonContent": "pf-c-table__button-content",
  "tableCheck": "pf-c-table__check",
  "tableColumnHelp": "pf-c-table__column-help",
  "tableColumnHelpAction": "pf-c-table__column-help-action",
  "tableCompoundExpansionToggle": "pf-c-table__compound-expansion-toggle",
  "tableControlRow": "pf-c-table__control-row",
  "tableDraggable": "pf-c-table__draggable",
  "tableExpandableRow": "pf-c-table__expandable-row",
  "tableExpandableRowContent": "pf-c-table__expandable-row-content",
  "tableFavorite": "pf-c-table__favorite",
  "tableIcon": "pf-c-table__icon",
  "tableIconInline": "pf-c-table__icon-inline",
  "tableInlineEditAction": "pf-c-table__inline-edit-action",
  "tableSort": "pf-c-table__sort",
  "tableSortIndicator": "pf-c-table__sort-indicator",
  "tableSubhead": "pf-c-table__subhead",
  "tableText": "pf-c-table__text",
  "tableToggle": "pf-c-table__toggle",
  "tableToggleIcon": "pf-c-table__toggle-icon",
  "themeDark": "pf-theme-dark"
};
"use strict";
exports.__esModule = true;
require('./banner.css');
exports.default = {
  "banner": "pf-c-banner",
  "button": "pf-c-button",
  "card": "pf-c-card",
  "modifiers": {
    "warning": "pf-m-warning",
    "gold": "pf-m-gold",
    "info": "pf-m-info",
    "blue": "pf-m-blue",
    "danger": "pf-m-danger",
    "red": "pf-m-red",
    "success": "pf-m-success",
    "green": "pf-m-green",
    "sticky": "pf-m-sticky",
    "disabled": "pf-m-disabled",
    "inline": "pf-m-inline"
  },
  "themeDark": "pf-theme-dark"
};
"use strict";
exports.__esModule = true;
require('./pagination.css');
exports.default = {
  "button": "pf-c-button",
  "formControl": "pf-c-form-control",
  "modifiers": {
    "bottom": "pf-m-bottom",
    "static": "pf-m-static",
    "first": "pf-m-first",
    "last": "pf-m-last",
    "sticky": "pf-m-sticky",
    "compact": "pf-m-compact",
    "pageInsets": "pf-m-page-insets",
    "displaySummary": "pf-m-display-summary",
    "displayFull": "pf-m-display-full",
    "insetNone": "pf-m-inset-none",
    "insetSm": "pf-m-inset-sm",
    "insetMd": "pf-m-inset-md",
    "insetLg": "pf-m-inset-lg",
    "insetXl": "pf-m-inset-xl",
    "inset_2xl": "pf-m-inset-2xl",
    "displaySummaryOnSm": "pf-m-display-summary-on-sm",
    "displayFullOnSm": "pf-m-display-full-on-sm",
    "insetNoneOnSm": "pf-m-inset-none-on-sm",
    "insetSmOnSm": "pf-m-inset-sm-on-sm",
    "insetMdOnSm": "pf-m-inset-md-on-sm",
    "insetLgOnSm": "pf-m-inset-lg-on-sm",
    "insetXlOnSm": "pf-m-inset-xl-on-sm",
    "inset_2xlOnSm": "pf-m-inset-2xl-on-sm",
    "displaySummaryOnMd": "pf-m-display-summary-on-md",
    "displayFullOnMd": "pf-m-display-full-on-md",
    "insetNoneOnMd": "pf-m-inset-none-on-md",
    "insetSmOnMd": "pf-m-inset-sm-on-md",
    "insetMdOnMd": "pf-m-inset-md-on-md",
    "insetLgOnMd": "pf-m-inset-lg-on-md",
    "insetXlOnMd": "pf-m-inset-xl-on-md",
    "inset_2xlOnMd": "pf-m-inset-2xl-on-md",
    "displaySummaryOnLg": "pf-m-display-summary-on-lg",
    "displayFullOnLg": "pf-m-display-full-on-lg",
    "insetNoneOnLg": "pf-m-inset-none-on-lg",
    "insetSmOnLg": "pf-m-inset-sm-on-lg",
    "insetMdOnLg": "pf-m-inset-md-on-lg",
    "insetLgOnLg": "pf-m-inset-lg-on-lg",
    "insetXlOnLg": "pf-m-inset-xl-on-lg",
    "inset_2xlOnLg": "pf-m-inset-2xl-on-lg",
    "displaySummaryOnXl": "pf-m-display-summary-on-xl",
    "displayFullOnXl": "pf-m-display-full-on-xl",
    "insetNoneOnXl": "pf-m-inset-none-on-xl",
    "insetSmOnXl": "pf-m-inset-sm-on-xl",
    "insetMdOnXl": "pf-m-inset-md-on-xl",
    "insetLgOnXl": "pf-m-inset-lg-on-xl",
    "insetXlOnXl": "pf-m-inset-xl-on-xl",
    "inset_2xlOnXl": "pf-m-inset-2xl-on-xl",
    "displaySummaryOn_2xl": "pf-m-display-summary-on-2xl",
    "displayFullOn_2xl": "pf-m-display-full-on-2xl",
    "insetNoneOn_2xl": "pf-m-inset-none-on-2xl",
    "insetSmOn_2xl": "pf-m-inset-sm-on-2xl",
    "insetMdOn_2xl": "pf-m-inset-md-on-2xl",
    "insetLgOn_2xl": "pf-m-inset-lg-on-2xl",
    "insetXlOn_2xl": "pf-m-inset-xl-on-2xl",
    "inset_2xlOn_2xl": "pf-m-inset-2xl-on-2xl"
  },
  "optionsMenu": "pf-c-options-menu",
  "optionsMenuToggle": "pf-c-options-menu__toggle",
  "pagination": "pf-c-pagination",
  "paginationNav": "pf-c-pagination__nav",
  "paginationNavControl": "pf-c-pagination__nav-control",
  "paginationNavPageSelect": "pf-c-pagination__nav-page-select",
  "paginationTotalItems": "pf-c-pagination__total-items",
  "themeDark": "pf-theme-dark"
};
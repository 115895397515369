"use strict";
exports.__esModule = true;
require('./chip.css');
exports.default = {
  "badge": "pf-c-badge",
  "button": "pf-c-button",
  "chip": "pf-c-chip",
  "chipIcon": "pf-c-chip__icon",
  "chipText": "pf-c-chip__text",
  "modifiers": {
    "overflow": "pf-m-overflow",
    "draggable": "pf-m-draggable"
  },
  "themeDark": "pf-theme-dark"
};
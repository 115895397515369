"use strict";
exports.__esModule = true;
require('./switch.css');
exports.default = {
  "modifiers": {
    "reverse": "pf-m-reverse",
    "off": "pf-m-off",
    "on": "pf-m-on"
  },
  "switch": "pf-c-switch",
  "switchInput": "pf-c-switch__input",
  "switchLabel": "pf-c-switch__label",
  "switchToggle": "pf-c-switch__toggle",
  "switchToggleIcon": "pf-c-switch__toggle-icon",
  "themeDark": "pf-theme-dark"
};
import { createIcon } from '../createIcon';

export const DataSinkIconConfig = {
  name: 'DataSinkIcon',
  height: 1024,
  width: 896,
  svgPath: 'M430.605,63 L576,223 L430.605,383 L384,339.574 L460.854,255 L179.538,255 C147.415,255.821 117.345,269.54 94.84,293.692 C72.297,317.884 59.882,349.916 59.882,383.888 L59.882,384.548 C59.882,418.481 72.245,450.28 94.695,474.088 C117.168,497.922 147.148,511 179.11,511 L717.06,511 C815.347,511 895.619,597.063 895.999359,702.789 C896.182,754.034 877.68,802.261 843.9,838.586 C810.069,874.964 765.02,895 717.061,895 L0,895 L0,831 L717.024,831 C717.033,831 717.043,831.023 717.053,831.023 C749.033,831.023 779.056,817.672 801.602,793.428 C824.092,769.244 836.41,737.142 836.288,703.035 C836.035,632.45 782.549,575 717.06,575 L179.11,575 C131.314,575 86.417,555.327 52.69,519.559 C18.822,483.642 0.171,435.694 0.171,384.548 L0.171,383.888 C0.171,332.847 18.785,284.761 52.584,248.489 C86.102,212.518 130.841,192.105 178.562,191 L460.854,191 L384,106.426 L430.605,63 Z M756,79 C833.196,79 896,141.804 896,219 C896,296.196 833.196,359 756,359 C678.804,359 616,296.196 616,219 C616,141.804 678.804,79 756,79 Z M756,143.013 C714.086,143.013 679.987,177.101 679.987,219 C679.987,260.9 714.086,294.987 756,294.987 C797.914,294.987 832.013,260.9 832.013,219 C832.013,177.101 797.914,143.013 756,143.013 Z',
  yOffset: 0,
  xOffset: 0,
};

export const DataSinkIcon = createIcon(DataSinkIconConfig);

export default DataSinkIcon;
"use strict";
exports.__esModule = true;
require('./text-input-group.css');
exports.default = {
  "button": "pf-c-button",
  "chipGroupMain": "pf-c-chip-group__main",
  "modifiers": {
    "disabled": "pf-m-disabled",
    "plain": "pf-m-plain",
    "icon": "pf-m-icon",
    "hint": "pf-m-hint"
  },
  "textInputGroup": "pf-c-text-input-group",
  "textInputGroupGroup": "pf-c-text-input-group__group",
  "textInputGroupIcon": "pf-c-text-input-group__icon",
  "textInputGroupMain": "pf-c-text-input-group__main",
  "textInputGroupText": "pf-c-text-input-group__text",
  "textInputGroupTextInput": "pf-c-text-input-group__text-input",
  "textInputGroupUtilities": "pf-c-text-input-group__utilities"
};
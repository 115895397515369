"use strict";
exports.__esModule = true;
require('./form-control.css');
exports.default = {
  "formControl": "pf-c-form-control",
  "modifiers": {
    "success": "pf-m-success",
    "plain": "pf-m-plain",
    "expanded": "pf-m-expanded",
    "icon": "pf-m-icon",
    "warning": "pf-m-warning",
    "search": "pf-m-search",
    "calendar": "pf-m-calendar",
    "clock": "pf-m-clock",
    "iconSprite": "pf-m-icon-sprite",
    "placeholder": "pf-m-placeholder",
    "resizeVertical": "pf-m-resize-vertical",
    "resizeHorizontal": "pf-m-resize-horizontal"
  },
  "themeDark": "pf-theme-dark"
};
"use strict";
exports.__esModule = true;
require('./empty-state.css');
exports.default = {
  "button": "pf-c-button",
  "emptyState": "pf-c-empty-state",
  "emptyStateBody": "pf-c-empty-state__body",
  "emptyStateContent": "pf-c-empty-state__content",
  "emptyStateIcon": "pf-c-empty-state__icon",
  "emptyStatePrimary": "pf-c-empty-state__primary",
  "emptyStateSecondary": "pf-c-empty-state__secondary",
  "modifiers": {
    "xs": "pf-m-xs",
    "sm": "pf-m-sm",
    "lg": "pf-m-lg",
    "xl": "pf-m-xl",
    "fullHeight": "pf-m-full-height",
    "primary": "pf-m-primary",
    "overpassFont": "pf-m-overpass-font"
  },
  "title": "pf-c-title"
};
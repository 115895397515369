"use strict";
exports.__esModule = true;
require('./table-scrollable.css');
exports.default = {
  "modifiers": {
    "borderRight": "pf-m-border-right",
    "borderLeft": "pf-m-border-left",
    "stickyHeader": "pf-m-sticky-header"
  },
  "scrollInnerWrapper": "pf-c-scroll-inner-wrapper",
  "scrollOuterWrapper": "pf-c-scroll-outer-wrapper",
  "table": "pf-c-table",
  "tableStickyColumn": "pf-c-table__sticky-column"
};
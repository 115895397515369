"use strict";
exports.__esModule = true;
require('./tabs.css');
exports.default = {
  "button": "pf-c-button",
  "modifiers": {
    "fill": "pf-m-fill",
    "scrollable": "pf-m-scrollable",
    "secondary": "pf-m-secondary",
    "noBorderBottom": "pf-m-no-border-bottom",
    "borderBottom": "pf-m-border-bottom",
    "box": "pf-m-box",
    "vertical": "pf-m-vertical",
    "current": "pf-m-current",
    "colorSchemeLight_300": "pf-m-color-scheme--light-300",
    "expandable": "pf-m-expandable",
    "nonExpandable": "pf-m-non-expandable",
    "expandableOnSm": "pf-m-expandable-on-sm",
    "nonExpandableOnSm": "pf-m-non-expandable-on-sm",
    "expandableOnMd": "pf-m-expandable-on-md",
    "nonExpandableOnMd": "pf-m-non-expandable-on-md",
    "expandableOnLg": "pf-m-expandable-on-lg",
    "nonExpandableOnLg": "pf-m-non-expandable-on-lg",
    "expandableOnXl": "pf-m-expandable-on-xl",
    "nonExpandableOnXl": "pf-m-non-expandable-on-xl",
    "expandableOn_2xl": "pf-m-expandable-on-2xl",
    "nonExpandableOn_2xl": "pf-m-non-expandable-on-2xl",
    "expanded": "pf-m-expanded",
    "pageInsets": "pf-m-page-insets",
    "overflow": "pf-m-overflow",
    "action": "pf-m-action",
    "active": "pf-m-active",
    "disabled": "pf-m-disabled",
    "ariaDisabled": "pf-m-aria-disabled",
    "insetNone": "pf-m-inset-none",
    "insetSm": "pf-m-inset-sm",
    "insetMd": "pf-m-inset-md",
    "insetLg": "pf-m-inset-lg",
    "insetXl": "pf-m-inset-xl",
    "inset_2xl": "pf-m-inset-2xl",
    "insetNoneOnSm": "pf-m-inset-none-on-sm",
    "insetSmOnSm": "pf-m-inset-sm-on-sm",
    "insetMdOnSm": "pf-m-inset-md-on-sm",
    "insetLgOnSm": "pf-m-inset-lg-on-sm",
    "insetXlOnSm": "pf-m-inset-xl-on-sm",
    "inset_2xlOnSm": "pf-m-inset-2xl-on-sm",
    "insetNoneOnMd": "pf-m-inset-none-on-md",
    "insetSmOnMd": "pf-m-inset-sm-on-md",
    "insetMdOnMd": "pf-m-inset-md-on-md",
    "insetLgOnMd": "pf-m-inset-lg-on-md",
    "insetXlOnMd": "pf-m-inset-xl-on-md",
    "inset_2xlOnMd": "pf-m-inset-2xl-on-md",
    "insetNoneOnLg": "pf-m-inset-none-on-lg",
    "insetSmOnLg": "pf-m-inset-sm-on-lg",
    "insetMdOnLg": "pf-m-inset-md-on-lg",
    "insetLgOnLg": "pf-m-inset-lg-on-lg",
    "insetXlOnLg": "pf-m-inset-xl-on-lg",
    "inset_2xlOnLg": "pf-m-inset-2xl-on-lg",
    "insetNoneOnXl": "pf-m-inset-none-on-xl",
    "insetSmOnXl": "pf-m-inset-sm-on-xl",
    "insetMdOnXl": "pf-m-inset-md-on-xl",
    "insetLgOnXl": "pf-m-inset-lg-on-xl",
    "insetXlOnXl": "pf-m-inset-xl-on-xl",
    "inset_2xlOnXl": "pf-m-inset-2xl-on-xl",
    "insetNoneOn_2xl": "pf-m-inset-none-on-2xl",
    "insetSmOn_2xl": "pf-m-inset-sm-on-2xl",
    "insetMdOn_2xl": "pf-m-inset-md-on-2xl",
    "insetLgOn_2xl": "pf-m-inset-lg-on-2xl",
    "insetXlOn_2xl": "pf-m-inset-xl-on-2xl",
    "inset_2xlOn_2xl": "pf-m-inset-2xl-on-2xl"
  },
  "tabs": "pf-c-tabs",
  "tabsAdd": "pf-c-tabs__add",
  "tabsItem": "pf-c-tabs__item",
  "tabsItemAction": "pf-c-tabs__item-action",
  "tabsItemActionIcon": "pf-c-tabs__item-action-icon",
  "tabsItemIcon": "pf-c-tabs__item-icon",
  "tabsItemText": "pf-c-tabs__item-text",
  "tabsLink": "pf-c-tabs__link",
  "tabsLinkToggleIcon": "pf-c-tabs__link-toggle-icon",
  "tabsList": "pf-c-tabs__list",
  "tabsScrollButton": "pf-c-tabs__scroll-button",
  "tabsToggle": "pf-c-tabs__toggle",
  "tabsToggleButton": "pf-c-tabs__toggle-button",
  "tabsToggleIcon": "pf-c-tabs__toggle-icon",
  "tabsToggleText": "pf-c-tabs__toggle-text",
  "themeDark": "pf-theme-dark"
};